// here just free electrons
// components which are not owned by one place in the app
// styled, without state or logic
import styled from '@emotion/styled';
import { colors } from 'src/js/nextgen/plushcare-components';
import { Tile } from 'src/js/nextgen/plushcare-components/TileContainer';

export const PaymentTile = styled(Tile)`
  padding: 16px;
  margin-bottom: 15px;
  align-items: flex-start;
  border-radius: 8px;

  #recurring-price {
    font-weight: 600;
  }
`;

export const HeadingRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: -webkit-fill-available;
  margin-bottom: 24px;
  line-height: 20px;
`;

export const SummaryHeading = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  width: -webkit-fill-available;
`;

export const TrialLabel = styled.h3`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.darkGray};
  margin: 0;
  width: -webkit-fill-available;
  text-align: right;
`;

export const SummaryBold = styled.h3`
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  letter-spacing: 0.3px;
  margin: 0;
`;

export const PaymentRow = styled.div<{ strong?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${(props => (props.strong ? colors.black : colors.darkGray))};
  font-size: 16px;
  width: 100%;

  .label {
    display: flex;
    justify-items: center;
    margin: 16px 0;
  }
  .label span {
    margin: 1px 8px 0px 0px;
  }
  .p {
    margin: 0;
  }
  #appointment-price {
    color: ${colors.black};
    margin: 0;
  }

  .after-hours {
    display: flex;
    justify-items: center;
    margin: 0 0 16px 0;
    span {
      margin: 0px 8px 0px 0px;
    }
  }

  #after-hours-fee {
    color: ${colors.black};
    margin: 0 0 16px 0;
  }
  #recurring-price {
    color: ${colors.adaGray};
  }
`;

export const SubText = styled.div`
  color: ${colors.darkGray};
  display: block;
  font-size: 16px;
  font-weight: 400;
`;

export const Anchor = styled.a`
  font-size: 18px;
  color: ${colors.adaRoyalBlue};
  margin-bottom: 16px;
`;

export const Addendum = styled.div<{ afterHoursApplied?: boolean, creditsApplied?: boolean, penaltyApplied?: boolean }>`
  p{
    margin-top: 0px;
    margin-bottom: 0px;
  }
  padding-top: 12px;
  border-top: ${({ afterHoursApplied, creditsApplied, penaltyApplied }) => (afterHoursApplied && !(creditsApplied || penaltyApplied) ? 'none' : `1px solid ${colors.backgroundGrey}`)};
  color: ${colors.adaGray};
  font-family: "TT Commons";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  width: 100%;

  #price-explanation {
    margin-top: 6px;
    line-height: 1.25em;
  }
`;

export const CenteredDiv = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledPage = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    width: 100%;
    height: 100%;
    min-height: 100vh;
  `;

export const AfterHoursExplanation = styled.div<{ creditsApplied: boolean, penaltyApplied: boolean }>`
    display: -webkit-inline-box;
    border: 1px solid ${colors.chineseWhite};
    background: ${colors.lotion};
    padding: 16px;
    p {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px; 
    }
    margin-bottom: ${({ creditsApplied, penaltyApplied }) => ((creditsApplied || penaltyApplied) ? '16px' : 'none')};
`;

export const BlueBadgeLabel = styled.div`
  display: inline-block;
  border-radius: 4px;
  background: ${colors.diamondLeague};
  padding: 4px 8px;
  font-weight: 400;
  color: ${colors.bodyText};
`;
