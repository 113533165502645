import styled from 'styled-components';

export const SelectionContainer = styled.div<{ inline?: boolean }>`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  justify-content: left;


  div:nth-of-type(2) {
    margin-left: ${({ inline }) => (inline ? '0.5rem' : '0')};
  }
`;
