import styled from 'styled-components';
import Check from 'public/img/nextgen/icon-selected.svg';
import { MouseEventHandler, ReactNode } from 'react';
import { Button } from 'src/js/nextgen/ads-components/Button';
import {
  InteractionTile, Checkmark, ButtonContainer,
} from 'src/js/nextgen/plushcare-components/TileContainer/TileContainer.styled';
import Image from 'next/image';
import { buttonTypeStyles } from 'src/js/nextgen/plushcare-components/Button/Button.styled';
import { colors } from 'src/js/nextgen/plushcare-components/helpers/plushcare-style-defaults';

type TileContainerProps = {
  selected?: boolean
  onClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  id?: string
  children?: ReactNode
  inline?: boolean
  alignStart?: boolean
  error?: boolean
  disabled?: boolean
}

// @TODO: the `alignStart` prop doesn't do anything, as the `<InteractionTile />` (which wraps the `<Tile />` component
// that actually uses the `alignStart` prop) sets an  `align-items: center;` style explicitly, which overrides the
// `alignStart` prop

const TileContainer = ({
  selected = false, onClick, id, children, inline, alignStart = true, error = false,
  disabled = false,
}: TileContainerProps) => {
  return (
    <InteractionTile
      className={`${selected ? 'selected' : ''}`}
      id={id}
      inline={inline}
      alignStart={alignStart}
      aria-disabled={disabled}
    >
      {!error && selected && (
        <Checkmark>
          <Image width={30} height={30} src={Check} alt="Checked" />
        </Checkmark>
      )}
      {children}
      <ButtonContainer id={`${id}-button-container`}>
        <TileButton
          onClick={onClick}
          className={selected ? 'insured-select' : 'uninsured-select'}
          type={error ? 'primary-tertiary' : selected ? 'primary' : 'primary-tertiary'}
          text={error ? 'Update' : selected ? 'Selected' : 'Select'}
          data-testid={`${id}-button`}
          buttonSize="small"
          fontSize="14px"
          isDisabled={disabled}
        />
      </ButtonContainer>
    </InteractionTile>
  );
};
export default TileContainer;

const TileButton = styled(Button) <{ selected?: boolean }>`
  &:disabled {
    pointer-events: none;

    &.insured-select {
      ${props => buttonTypeStyles['primary-tertiary'](props.theme).styles}
      background: ${props => (props.theme.colors.primary.focus)};
    }

    &:not(.insured-select) {
      background: ${props => props.theme.colors.background};
      color: ${colors.adaGray};
      pointer-events: none;
      border-color: ${colors.backgroundGrey};
    }
  }
`;
